import { Box, TextField } from "@mui/material";
import { useFormik } from "formik";
import { number, object, string } from "yup";
import { unpackResponse, useClient } from "../client";
import { Schema } from "../client/types";
import { caughtValueToString } from "../utils/caught-error";
import { DateField, getDateFieldProps } from "./date-field";
import { FormDialog } from "./form-dialog";
import { getNumberFieldProps, NumberField } from "./number-field";
import { getTextFieldProps } from "./text-field";

export function AddWarrantyReturnDialog(props: {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  location: Schema["Location"];
}) {
  const client = useClient();
  const { location } = props;

  return (
    <WarrantyReturnDialog
      {...props}
      title="Add Warranty Return"
      submitLabel="Add"
      initialValues={{ dueDate: "", feeAmount: 0, itemDescription: "" }}
      onSubmit={async (values) => {
        await unpackResponse(
          client.POST("/locations/{locationId}/warranty-returns", {
            params: { path: { locationId: location.id } },
            body: {
              ...values,
              id: 0,
              companyName: "Unknown",
              locationId: location.id,
              locationName: location.name,
              status: "PENDING",
            },
          }),
        );
      }}
    />
  );
}

export function EditWarrantyReturnDialog(props: {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  warrantyReturn: Schema["WarrantyReturn"];
}) {
  const client = useClient();
  const { warrantyReturn } = props;

  return (
    <WarrantyReturnDialog
      {...props}
      title="Edit Warranty Return"
      submitLabel="Save"
      initialValues={warrantyReturn}
      onSubmit={async (values) => {
        await unpackResponse(
          client.PUT(
            "/locations/{locationId}/warranty-returns/{warrantyReturnId}",
            {
              params: {
                path: {
                  locationId: warrantyReturn.locationId,
                  warrantyReturnId: props.warrantyReturn.id,
                },
              },
              body: {
                ...warrantyReturn,
                ...values,
              },
            },
          ),
        );
      }}
    />
  );
}

type FormValues = Pick<
  Schema["WarrantyReturn"],
  "itemDescription" | "dueDate" | "feeAmount"
>;

function WarrantyReturnDialog(props: {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  onSubmit: (values: FormValues) => Promise<void>;
  initialValues: FormValues;
  title: string;
  submitLabel: string;
  submitColor?: "error";
}) {
  const { initialValues } = props;
  const formik = useFormik({
    initialValues: {
      itemDescription: initialValues.itemDescription,
      dueDate: initialValues.dueDate,
      feeAmount: initialValues.feeAmount,
    },
    validationSchema: object().shape({
      itemDescription: string().required("Required"),
      dueDate: string().required("Required"),
      feeAmount: number().moreThan(0, "Fee amount must be more than 0."),
    }),
    async onSubmit(values) {
      try {
        await props.onSubmit(values);
        props.onSuccess();
        props.onClose();
      } catch (error) {
        formik.setStatus(caughtValueToString(error));
      }
    },
  });

  return (
    <FormDialog {...props} form={formik}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
        <TextField
          {...getTextFieldProps(formik, "itemDescription")}
          label="Item Description"
          variant="standard"
          fullWidth
        />
        <DateField
          {...getDateFieldProps(formik, "dueDate")}
          label="Return due date"
        />
        <NumberField
          {...getNumberFieldProps(formik, "feeAmount")}
          label="Non-return Fee"
          adornment="$"
          fullWidth
        />
      </Box>
    </FormDialog>
  );
}
