import {
  Box,
  Card,
  CardHeader,
  Divider,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { Plus } from "react-feather";
import { unpackResponse, useClient, useClientSWR } from "../client";
import { Schema } from "../client/types";
import { warrantyStatus } from "../constants/warrantyStatus";
import { useQueryPermissions } from "../hooks/useQueryPermissions";
import { formatCurrency, formatDate } from "../utils/formatters";
import { ConfirmDialog } from "./confirm-dialog";
import { DialogIconButton } from "./dialog-button";
import { DummyRows } from "./dummy-rows";
import { LocationFetchErrorCard } from "./info-card";
import { MoreMenu, MoreMenuDialogItem } from "./more-menu";
import { TableColumnHeadCell, TableRowHeadCell } from "./table-elements";
import { usePageable } from "./use-pageable";
import { ViewTable } from "./view-table";
import { ViewTablePagination } from "./view-table-pagination";
import {
  AddWarrantyReturnDialog,
  EditWarrantyReturnDialog,
} from "./warranty-return-dialog";

function WarrantiesMenu(props: {
  warrantyReturn: Schema["WarrantyReturn"];
  revalidateView: () => void;
}) {
  const client = useClient();

  return (
    <MoreMenu>
      <MoreMenuDialogItem
        renderDialog={(dialog) => (
          <ConfirmDialog
            {...dialog}
            title="Mark warranty return as received?"
            description="This warranty return will be marked as received."
            submitLabel="Mark as received"
            onSubmit={async () => {
              await unpackResponse(
                client.PATCH(
                  "/locations/{locationId}/warranty-returns/{warrantyReturnId}/receive",
                  {
                    params: {
                      path: {
                        locationId: props.warrantyReturn.locationId,
                        warrantyReturnId: props.warrantyReturn.id,
                      },
                    },
                  },
                ),
              );

              props.revalidateView();
            }}
          />
        )}
      >
        Mark as received
      </MoreMenuDialogItem>

      <MoreMenuDialogItem
        renderDialog={(dialog) => {
          return (
            <EditWarrantyReturnDialog
              {...dialog}
              {...props}
              onSuccess={props.revalidateView}
            />
          );
        }}
      >
        Edit
      </MoreMenuDialogItem>

      <Divider />

      <MoreMenuDialogItem
        renderDialog={(dialog) => (
          <ConfirmDialog
            {...dialog}
            title="Delete warranty return"
            description="This warranty return will be permanently deleted."
            submitLabel="Delete"
            submitColor="error"
            onSubmit={async () => {
              await unpackResponse(
                client.DELETE(
                  "/locations/{locationId}/warranty-returns/{warrantyReturnId}",
                  {
                    params: {
                      path: {
                        locationId: props.warrantyReturn.locationId,
                        warrantyReturnId: props.warrantyReturn.id,
                      },
                    },
                  },
                ),
              );

              props.revalidateView();
            }}
          />
        )}
      >
        Delete
      </MoreMenuDialogItem>
    </MoreMenu>
  );
}

export function LocationWarrantyReturnsCard(props: {
  location: Schema["Location"];
  subheader?: string;
  revalidateView: () => void;
}) {
  const canManageWarrantyReturns = useQueryPermissions([
    { type: "MANAGE_WARRANTY_RETURNS" },
  ]).isAllowed("MANAGE_WARRANTY_RETURNS");

  const ROW_HEIGHT = 76;
  const pageable = usePageable({
    initialSortProp: "dueDate",
    initialSortDirection: "desc",
  });
  const {
    data: warrantiesResponse,
    error: warrantiesError,
    mutate: revalidateWarranitesView,
  } = useClientSWR("/locations/{locationId}/warranty-returns", {
    params: { path: { locationId: props.location.id }, query: pageable.query },
  });

  const revalidateView = () => {
    props.revalidateView();
    revalidateWarranitesView();
  };

  const header = (
    <CardHeader
      title="Warranty returns"
      titleTypographyProps={{ variant: "h5" }}
      subheader={props.subheader}
      subheaderTypographyProps={{ variant: "body2", sx: { marginTop: 2 } }}
      action={
        canManageWarrantyReturns && (
          <DialogIconButton
            label="Add warranty return"
            renderDialog={(dialogProps) => (
              <AddWarrantyReturnDialog
                {...dialogProps}
                location={props.location}
                onSuccess={revalidateView}
              />
            )}
          >
            <Plus />
          </DialogIconButton>
        )
      }
    />
  );

  if (warrantiesError) {
    return (
      <Card>
        {header}
        <LocationFetchErrorCard error={warrantiesError} />
      </Card>
    );
  }

  const table = (
    <ViewTable
      tableLayout="fixed"
      head={
        <TableRow>
          <TableColumnHeadCell>Item description</TableColumnHeadCell>
          <TableColumnHeadCell>Return due date</TableColumnHeadCell>
          <TableColumnHeadCell>Non-return fee</TableColumnHeadCell>
          <TableColumnHeadCell>Status</TableColumnHeadCell>
          {canManageWarrantyReturns && <TableColumnHeadCell />}
        </TableRow>
      }
      body={
        !warrantiesResponse ? (
          <DummyRows
            columns={5}
            rows={pageable.pageSize}
            rowHeight={ROW_HEIGHT}
          />
        ) : (
          warrantiesResponse.content.map((warrantyReturn) => {
            return (
              <TableRow
                key={warrantyReturn.id}
                sx={{ height: ROW_HEIGHT }}
                hover
              >
                <TableRowHeadCell>
                  <Typography
                    variant="body2"
                    fontWeight="bold"
                    component="span"
                  >
                    {warrantyReturn.itemDescription}
                  </Typography>
                </TableRowHeadCell>
                <TableCell>
                  {formatDate(warrantyReturn.dueDate, "long")}
                </TableCell>
                <TableCell>
                  {formatCurrency("USD", warrantyReturn.feeAmount)}
                </TableCell>
                <TableCell>
                  <Typography
                    color={
                      warrantyReturn.status === "PENDING"
                        ? "warning.main"
                        : "textPrimary"
                    }
                    variant="body2"
                  >
                    {warrantyStatus.format(warrantyReturn.status)}
                  </Typography>
                </TableCell>
                {canManageWarrantyReturns && (
                  <TableCell align="right">
                    <WarrantiesMenu
                      warrantyReturn={warrantyReturn}
                      revalidateView={revalidateView}
                    />
                  </TableCell>
                )}
              </TableRow>
            );
          })
        )
      }
      pagination={
        <ViewTablePagination
          {...pageable.paginationProps}
          totalElements={warrantiesResponse?.totalElements}
        />
      }
    />
  );

  const emptyState = (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingY: 10,
        paddingX: 4,
      }}
    >
      <Typography variant="body2">No warranty returns yet.</Typography>
    </Box>
  );

  return (
    <Card>
      {header}
      {warrantiesResponse && warrantiesResponse.content.length === 0
        ? emptyState
        : table}
    </Card>
  );
}
