import {
  CheckCircleOutlined,
  ErrorOutline,
  RemoveCircleOutline,
  Schedule,
} from "@mui/icons-material";
import { Card, Grid, Link, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { ReactElement, ReactNode } from "react";
import {
  AlertCircle,
  Box as BoxIcon,
  Cpu,
  CreditCard,
  Edit3,
  Monitor,
} from "react-feather";
import { Link as ReactRouterLink, useParams } from "react-router-dom";
import { unpackResponse, useClient, useClientSWR } from "../../client";
import { Schema } from "../../client/types";
import { billingStatus } from "../../constants/billingStatus";
import { deviceStatusV2 } from "../../constants/locationConfiguration/deviceStatus";
import { locationStatus } from "../../constants/locationStatus";
import { useQueryPermissions } from "../../hooks/useQueryPermissions";
import { getCommonGroupStatus } from "../../pages/Device/device-cards";
import { countBy, groupByToEntries } from "../../utils/common";
import { EnumFormatter, EnumMapper } from "../../utils/enum-formatter";
import { formatDate } from "../../utils/formatters";
import { Address } from "../address";
import { LocationBillingToast } from "../billing-toast";
import { ConfirmDialog } from "../confirm-dialog";
import { DialogIconButton } from "../dialog-button";
import { EditLocationAddressDialog } from "../edit-location-address-dialog";
import { EditLocationNameDialog } from "../edit-location-dialog";
import { EditLocationShippingAddressDialog } from "../edit-location-shipping-address-dialog";
import { LocationFetchErrorCard } from "../info-card";
import LoadingSpinner from "../loading-spinner";
import { LocationContactListCard } from "../location-contact-list-card";
import { LocationDevicesCard } from "../location-devices-card";
import { LocationInternalInfoCard } from "../location-internal-info-card";
import { LocationSubscriptionDetailsCardFetch } from "../location-subscription-details-card";
import { LocationWarrantyReturnsCard } from "../location-warranty-returns-card";
import { DialogState, MoreMenu, MoreMenuDialogItem } from "../more-menu";
import { NameCard } from "../name-card";
import { RoleGuard, useUserHasRole } from "../role-guard";
import { ViewCard } from "../view-card";
import { ViewHeader } from "../view-header";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

export const PmbTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
};

export function LocationDetailFetch() {
  const { locationId } = useParams<"locationId">();
  const locationResult = useClientSWR("/locations/{locationId}", {
    params: { path: { locationId: Number(locationId) } },
  });
  const subscriptionResult = useClientSWR(
    "/locations/{locationId}/subscription",
    { params: { path: { locationId: Number(locationId) } } },
  );

  const revalidateView = () => {
    locationResult.mutate();
    subscriptionResult.mutate();
  };

  const error = locationResult.error || subscriptionResult.error;
  if (error) {
    return <LocationFetchErrorCard error={error} />;
  }

  if (locationResult.data && subscriptionResult.data) {
    return (
      <LocationDetailView
        location={locationResult.data}
        subscription={subscriptionResult.data}
        revalidateView={revalidateView}
      />
    );
  }

  return <LoadingSpinner />;
}

const deviceCategory = new EnumFormatter<
  Schema["DeviceUpdate"]["deviceCategory"]
>({
  CHARGER: "Charger",
  GATEWAY: "Gateway",
  SCREEN: "Screen",
});

const deviceCategoryIcon = new EnumMapper<
  Schema["DeviceUpdate"]["deviceCategory"],
  ReactNode
>(
  {
    CHARGER: <CreditCard />,
    GATEWAY: <Cpu />,
    SCREEN: <Monitor />,
  },
  <AlertCircle />,
);

const deviceCategoryOrder = new EnumMapper<
  Schema["DeviceUpdate"]["deviceCategory"],
  number
>(
  {
    GATEWAY: 4,
    CHARGER: 3,

    // Device group is order 2
    SCREEN: 1,
  },
  -1,
);

function summariesStatuses(statuses: [string, number][]) {
  if (statuses.length === 1) {
    return statuses[0][0];
  }

  return statuses.map(([status, count]) => `${count}x ${status}`).join(", ");
}

function summariseLabel(count: number, label: string) {
  return `${count}x ${label}`;
}

function LocationDevicesCardFetch(props: { locationId: number }) {
  const { locationId } = props;
  const { data: devices, error } = useClientSWR(
    "/locations/{locationId}/devices",
    {
      params: { path: { locationId: locationId } },
    },
  );
  const { data: deviceGroups, error: deviceGroupsError } = useClientSWR(
    "/locations/{locationId}/device-groups",
    {
      params: { path: { locationId: locationId } },
    },
  );

  if (error || deviceGroupsError) {
    return (
      <Card>
        <Box display="flex" alignItems="center" justifyContent="center">
          Error Fetching Devices
        </Box>
      </Card>
    );
  }
  if (devices && deviceGroups) {
    const groups = deviceGroups.content;
    const deviceGroupsSummary =
      groups.length === 0
        ? null
        : {
            label: summariseLabel(groups.length, "Device group"),
            icon: <BoxIcon />,
            order: 2,
            status: summariesStatuses(
              Object.entries(
                countBy(
                  groups.map((g) => getCommonGroupStatus(g.devices ?? [])),
                  (g) => g,
                ),
              ),
            ),
          };

    const devicesSummaries = groupByToEntries(
      devices,
      (d) => d.deviceCategory,
    ).map(([category, devices]) => {
      if (devices.length === 0) {
        return null;
      }

      return {
        label: summariseLabel(devices.length, deviceCategory.get(category)),
        icon: deviceCategoryIcon.get(category),
        status: summariesStatuses(
          Object.entries(
            countBy(devices, (d) => deviceStatusV2.format(d.status)),
          ),
        ),
        order: deviceCategoryOrder.get(category),
      };
    });

    return (
      <LocationDevicesCard
        locationId={locationId}
        summaries={[...devicesSummaries, deviceGroupsSummary]
          .filter((item): item is NonNullable<typeof item> => !!item)
          .sort((a, b) => b.order - a.order)}
      />
    );
  }

  return <LocationDevicesCard locationId={locationId} />;
}

const statusIconSrc = new EnumMapper<Schema["LocationStatus"], ReactElement>(
  {
    ACTIVE: <CheckCircleOutlined color="success" />,
    PENDING_INSTALLATION: <Schedule color="warning" />,
    PENDING_OPENING: <Schedule color="warning" />,
    CLOSED: <RemoveCircleOutline color="disabled" />,
    LEGACY: <RemoveCircleOutline color="disabled" />,
  },
  <ErrorOutline color="error" />,
);

function getStatusInfo(location: Schema["LocationBase"]) {
  switch (location.locationStatus) {
    case "PENDING_INSTALLATION":
      return location.installDate
        ? `Exp. ${formatDate(location.installDate, "medium")}`
        : null;

    case "PENDING_OPENING":
      return location.grandOpeningDate
        ? `Exp. ${formatDate(location.grandOpeningDate, "medium")}`
        : null;

    case "LEGACY":
      return "Location devices are not connected.";

    default:
      return null;
  }
}

function ToggleLocationBillingStatusDialog(
  props: DialogState & {
    location: Schema["LocationBase"];
    revalidateView: () => void;
  },
) {
  const { location, ...dialogProps } = props;
  const isActive = location.billingStatus === "ACTIVE";
  const client = useClient();

  const content = isActive
    ? {
        title: `Set ${location.name} as not supported?`,
        submitLabel: "Set as not supported",
        targetBillingStatus: "NOT_SUPPORTED" as const,
      }
    : {
        title: `Set ${location.name} as active?`,
        submitLabel: "Set as active",
        targetBillingStatus: "ACTIVE" as const,
      };

  return (
    <ConfirmDialog
      {...dialogProps}
      title={content.title}
      description="You can revert this action at any time."
      submitLabel={content.submitLabel}
      onSubmit={async () => {
        await unpackResponse(
          client.PATCH("/locations/{locationId}/internals", {
            params: { path: { locationId: location.id } },
            body: { billingStatus: content.targetBillingStatus },
          }),
        );
        props.revalidateView();
      }}
    />
  );
}

function StatusCard(props: {
  location: Schema["LocationBase"];
  subscription: Schema["Subscription"];
  revalidateView: () => void;
}) {
  const { location, subscription } = props;
  const permissions = useQueryPermissions([
    { type: "TOGGLE_LOCATION_BILLING_STATUS", subscription },
  ]);
  const icon = statusIconSrc.get(location.locationStatus);
  const billingStatusLabel =
    location.billingStatus === "NOT_SUPPORTED"
      ? ` (${billingStatus.format(location.billingStatus)})`
      : "";

  const label = `${locationStatus.get(location.locationStatus)}${billingStatusLabel}`;
  const info = getStatusInfo(location);

  const isActive = location.billingStatus === "ACTIVE";

  return (
    <ViewCard
      title="Status"
      headerAction={
        permissions.isAllowed("TOGGLE_LOCATION_BILLING_STATUS") && (
          <MoreMenu>
            <MoreMenuDialogItem
              renderDialog={(dialogProps) => {
                return (
                  <ToggleLocationBillingStatusDialog
                    {...dialogProps}
                    {...props}
                  />
                );
              }}
            >
              {isActive ? "Set as not supported" : "Set as active"}
            </MoreMenuDialogItem>
          </MoreMenu>
        )
      }
      content={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            color: "warning",
          }}
        >
          {icon}

          <div>
            <Typography variant="body1">{label}</Typography>
            {info && <Typography variant="body2">{info}</Typography>}
          </div>
        </Box>
      }
    />
  );
}

function LocationNameCard(props: {
  location: Schema["LocationBase"];
  revalidateView: () => void;
}) {
  const userCanNavigateToCompanyDetail = useUserHasRole([
    "SYSTEM_ADMIN",
    "PMB_ADMIN",
    "PMB_VIEWER",
    "COMPANY_ADMIN",
  ]);
  const userCanUpdateLocation = useUserHasRole([
    "SYSTEM_ADMIN",
    "PMB_ADMIN",
    "LOCATION_ADMIN",
    "COMPANY_ADMIN",
  ]);
  const userCanUpdateCustomerNumber = useUserHasRole([
    "SYSTEM_ADMIN",
    "PMB_ADMIN",
  ]);

  return (
    <NameCard
      name={props.location.name}
      subheading={`Customer ID: ${props.location.customerNumber}`}
      action={
        userCanUpdateLocation && (
          <DialogIconButton
            label="Edit location"
            renderDialog={(dialogProps) => (
              <EditLocationNameDialog
                {...dialogProps}
                location={props.location}
                onSuccess={props.revalidateView}
                userCanUpdateCustomerNumber={userCanUpdateCustomerNumber}
              />
            )}
          >
            <Edit3 />
          </DialogIconButton>
        )
      }
      footer={
        <Box sx={{ textAlign: "center", padding: 4 }}>
          {"Billed to "}

          {userCanNavigateToCompanyDetail ? (
            <Link
              component={ReactRouterLink}
              to={`/company/${props.location.company?.id}/profile`}
            >
              {props.location.company?.name}
            </Link>
          ) : (
            props.location.company?.name
          )}
        </Box>
      }
    />
  );
}

function LocationDetailView(props: {
  location: Schema["LocationBase"];
  subscription: Schema["Subscription"];
  revalidateView: () => void;
}) {
  return (
    <>
      <ViewHeader
        title={props.location.name}
        breadcrumb={[
          {
            label: "Locations",
            url: `/location/list`,
          },
          {
            label: props.location.name,
          },
        ]}
      />

      <LocationBillingToast locationId={props.location.id} />

      <Grid container spacing={6}>
        <Grid item xs={12} lg={3}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 6 }}>
            <LocationNameCard {...props} />

            <RoleGuard allowedRoles={["PMB_ADMIN", "PMB_VIEWER"]}>
              <StatusCard {...props} />
            </RoleGuard>

            <RoleGuard
              allowedRoles={[
                "PMB_ADMIN",
                "PMB_VIEWER",
                "COMPANY_ADMIN",
                "LOCATION_ADMIN",
              ]}
            >
              <LocationSubscriptionDetailsCardFetch
                locationId={props.location.id}
              />
            </RoleGuard>

            <ViewCard
              title="Location address"
              content={<Address {...props.location.address} />}
              headerAction={
                <RoleGuard
                  allowedRoles={[
                    "PMB_ADMIN",
                    "COMPANY_ADMIN",
                    "LOCATION_ADMIN",
                  ]}
                >
                  <DialogIconButton
                    label="Edit location address"
                    renderDialog={(dialogProps) => (
                      <EditLocationAddressDialog
                        {...dialogProps}
                        location={props.location}
                        onSuccess={props.revalidateView}
                      />
                    )}
                  >
                    <Edit3 />
                  </DialogIconButton>
                </RoleGuard>
              }
            />

            <ViewCard
              title="Shipping address"
              content={
                props.location.shippingAddress ? (
                  <Address {...props.location.shippingAddress} />
                ) : (
                  <Typography variant="body2">Not specified.</Typography>
                )
              }
              headerAction={
                <RoleGuard
                  allowedRoles={[
                    "PMB_ADMIN",
                    "COMPANY_ADMIN",
                    "LOCATION_ADMIN",
                  ]}
                >
                  <DialogIconButton
                    label="Edit shipping address"
                    renderDialog={(dialogProps) => (
                      <EditLocationShippingAddressDialog
                        {...dialogProps}
                        location={props.location}
                        onSuccess={props.revalidateView}
                      />
                    )}
                  >
                    <Edit3 />
                  </DialogIconButton>
                </RoleGuard>
              }
            />

            <RoleGuard allowedRoles={["PMB_ADMIN", "PMB_VIEWER"]}>
              <LocationInternalInfoCard
                {...props.location}
                revalidateView={props.revalidateView}
              />
            </RoleGuard>
          </Box>
        </Grid>

        <Grid item xs={12} lg={9}>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={12} md={12}>
              <LocationDevicesCardFetch locationId={props.location.id} />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <LocationContactListCard
                {...props.location}
                revalidateView={props.revalidateView}
              />
            </Grid>

            <RoleGuard allowedRoles={["PMB_ADMIN", "PMB_VIEWER"]}>
              <Grid item xs={12} sm={12} md={12}>
                <LocationWarrantyReturnsCard {...props} />
              </Grid>
            </RoleGuard>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
