import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material";
import { ReactNode } from "react";

/**
 * ViewTable component should be used for full view tables.
 */
export function ViewTable(props: {
  head: ReactNode;
  body: ReactNode;
  pagination?: ReactNode;
  minWidth?: number;
  tableLayout?: "fixed";
}) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: props.minWidth, tableLayout: props.tableLayout }}>
        <TableHead>{props.head}</TableHead>
        <TableBody>{props.body}</TableBody>
      </Table>

      {props.pagination}
    </TableContainer>
  );
}
