import { useParams } from "react-router-dom";
import { useClientSWR } from "../client";
import { Schema } from "../client/types";
import { LocationFetchErrorCard } from "./info-card";
import LoadingSpinner from "./loading-spinner";
import { LocationWarrantyReturnsCard } from "./location-warranty-returns-card";
import { ViewHeader } from "./view-header";

function LocationWarrantyReturnsView(props: {
  location: Schema["Location"];
  revalidateView: () => void;
}) {
  return (
    <>
      <ViewHeader
        title={`${props.location.name} Warranty Returns`}
        breadcrumb={[
          {
            label: "Locations",
            url: `/location/list`,
          },
          {
            label: props.location.name,
            url: `/location/${props.location.id}/profile`,
          },
          {
            label: "Warranty Returns",
          },
        ]}
      />

      <LocationWarrantyReturnsCard
        {...props}
        subheader="Items listed below that are not received by the return due date will be automatically charged to your account during the next monthly billing cycle."
      />
    </>
  );
}

export function LocationWarrantyReturnsFetch() {
  const { locationId } = useParams<"locationId">();
  const locationResult = useClientSWR("/locations/{locationId}", {
    params: { path: { locationId: Number(locationId) } },
  });

  const revalidateView = () => {
    locationResult.mutate();
  };

  const error = locationResult.error;
  if (error) {
    return <LocationFetchErrorCard error={error} />;
  }

  if (locationResult.data) {
    return (
      <LocationWarrantyReturnsView
        location={locationResult.data}
        revalidateView={revalidateView}
      />
    );
  }

  return <LoadingSpinner />;
}
