import { CreditCard, EventRepeat } from "@mui/icons-material";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { useCallback } from "react";
import { Award, DollarSign, Home, Package, Users } from "react-feather";
import useSWR from "swr";
import { PMBClient, useClient } from "../../client";
import { SidebarItemsType } from "../../types/sidebar";
import { useUserInfo } from "../../ui/user-info-context";
import { includesAnyOf } from "../../utils/one-of";

const adminHome: SidebarItemsType = {
  href: "/",
  icon: Home,
  title: "Home",
};

const company: SidebarItemsType = {
  href: "/company/list",
  icon: Award,
  title: "Companies",
};

const location: SidebarItemsType = {
  href: "/location/list",
  icon: LocationOnOutlinedIcon,
  title: "Locations",
};

const contact: SidebarItemsType = {
  href: "/contact/list",
  icon: Users,
  title: "Contacts",
};

const transaction: SidebarItemsType = {
  href: "/transaction/list",
  icon: DollarSign,
  title: "Transactions",
};

export function useDashboardItems(): {
  title: string;
  pages: SidebarItemsType[];
}[] {
  const client = useClient();
  const { roles, principal } = useUserInfo();
  const principalId = principal.id.toString();
  const fetcher = useCallback(async () => {
    if (includesAnyOf(["PMB_ADMIN", "SYSTEM_ADMIN"], roles)) {
      return pmbAdminDashboardItems();
    }

    if (includesAnyOf(["PMB_VIEWER"], roles)) {
      return pmbViewerDashboardItems();
    }

    if (includesAnyOf(["COMPANY_ADMIN"], roles) && principal.companyId) {
      return await getCompanyItems({ client, companyId: principal.companyId });
    }

    if (includesAnyOf(["LOCATION_ADMIN"], roles) && principal.locationId) {
      return await getLocationDashboardItems({
        client,
        locationId: principal.locationId,
        isAdmin: true,
      });
    }

    if (includesAnyOf(["LOCATION_VIEWER"], roles) && principal.locationId) {
      return await getLocationDashboardItems({
        client,
        locationId: principal.locationId,
        isAdmin: false,
      });
    }
  }, [client, principal.companyId, principal.locationId, roles]);
  const { data } = useSWR(principalId, fetcher);

  return (
    data ?? [
      {
        title: "Pages",
        // This is really bad code and UX, but no time was allocated to fixing it yet.
        pages: [],
      },
    ]
  );
}

function pmbAdminDashboardItems() {
  return [
    {
      title: "Pages",
      pages: [adminHome, company, location, contact, transaction],
    },
  ];
}

function pmbViewerDashboardItems() {
  return [
    {
      title: "Pages",
      pages: [adminHome, company, location, contact],
    },
  ];
}

async function getCompanyItems({
  client,
  companyId,
}: {
  client: PMBClient;
  companyId: number;
}) {
  const { data: locationsData } = await client.GET(
    "/companies/{companyId}/locations",
    { params: { path: { companyId } } },
  );

  if (!locationsData) {
    throw new Error("Failed to fetch company admin sidebar items.");
  }

  const home: SidebarItemsType = {
    href: `/company/${companyId}/profile`,
    icon: Home,
    title: "Home",
  };

  const pms: SidebarItemsType = {
    href: `/company/${companyId}/payment-methods`,
    icon: CreditCard,
    title: "Payment Methods",
  };

  const subs: SidebarItemsType = {
    href: `/company/${companyId}/subscriptions`,
    icon: EventRepeat,
    title: "Subscriptions",
  };

  const locations = await Promise.all(
    locationsData.content.map(async (location): Promise<SidebarItemsType> => {
      const { data: warrantyReturns } = await client.GET(
        "/locations/{locationId}/warranty-returns",
        { params: { path: { locationId: location.id } } },
      );

      return {
        icon: LocationOnOutlinedIcon,
        title: location.name,
        children: [
          {
            href: `/location/${location.id}/profile`,
            icon: BadgeOutlinedIcon,
            title: "Profile",
          },
          warrantyReturns?.content.length
            ? {
                href: `/location/${location.id}/warranty-returns`,
                icon: Package,
                title: "Warranty Returns",
              }
            : null,
          {
            href: `/location/${location.id}/subscription`,
            icon: EventRepeat,
            title: "Subscription",
          },
        ].filter((item): item is NonNullable<typeof item> => !!item),
      };
    }),
  );

  return [
    {
      title: "Pages",
      pages: [home, pms, subs, ...locations],
    },
  ];
}

async function getLocationDashboardItems({
  client,
  locationId,
  isAdmin,
}: {
  client: PMBClient;
  locationId: number;
  isAdmin: boolean;
}) {
  const { data, error } = await client.GET("/locations/{locationId}", {
    params: { path: { locationId } },
  });

  if (error) {
    throw new Error("Failed to fetch location dashboard items.");
  }

  const home = {
    href: `/location/${locationId}/profile`,
    icon: Home,
    title: "Home",
  };

  const subscription = {
    href: `/location/${data.id}/subscription`,
    icon: EventRepeat,
    title: "Subscription",
  };

  const pages: SidebarItemsType[] = [];
  pages.push(home);

  if (isAdmin) {
    pages.push(subscription);
  }

  return [
    {
      title: "Pages",
      pages: pages,
    },
  ];
}
